<template>
  <section class="home">
    <Loader :loading="loading" />
    <div class="home__header">
      <div
        class="home__header-container"
        :style="{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.26) 100%), radial-gradient(105.32% 44.51% at 67.01% 50%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.56) 100%), url(${
            featuredExhibition.slug ? featuredExhibition.home_page_image : home_page_image
          }),  lightgray`,
        }"
      >
        <div class="home__header-container-content">
          <p class="home__header-sub-title text-sub-title" v-if="featuredExhibition.slug != null">
            {{ featuredExhibition.dates }} | {{ featuredExhibition.exhibition_type }}
          </p>
          <div class="home__header-container-content__line">
            <h2 class="home__header-title text-title">
              <b>
                {{ !featuredExhibition.slug ? "browngrotta arts: " : '' }}
              </b>
              {{
                featuredExhibition.slug
                  ? featuredExhibition.title
                  : "international art textiles, mixed media and dimensional art"
              }}
            </h2>
          </div>
          <div
            v-if="featuredExhibition && featuredExhibition.slug && featuredExhibition.home_description"
            class="collect-editor collect-editor--white home__header-description"
            v-html="featuredExhibition.home_description"
          ></div>
          <div class="home__header-buttons">
            <BaseButton
              class="home__header-button button--white"
              v-if="featuredExhibition.slug"
              text="PLAN YOUR VISIT"
              @click="$router.push({ path: `/exhibitions/${featuredExhibition.slug}` })"
            />
            <BaseButton
              class="home__header-button button--white"
              v-else
              text="ABOUT GALLERY"
              @click="$router.push({ path: '/about' })"
            />
            <BaseButton
              class="home__header-button button--outline-white"
              text="ALL EXHIBITIONS"
              @click="$router.push({ path: '/exhibitions' })"
            />
            <BaseButton
              class="home__header-button button--outline-white"
              text="AVAILABLE WORKS"
              @click="scrollToElement"
            />
          </div>
          <div class="mobile-copyright" ref="works">
            <Copyright class="home__header-copyright">
              {{ featuredExhibition.copyright ? featuredExhibition.copyright : "Tom Grotta" }}
            </Copyright>
            <SharePlatforms class="home__share-platforms home__share-platforms--mobile" />
          </div>
        </div>

        <!-- <IconScroll class="home__icon-scroll" /> -->
        <SharePlatforms class="home__share-platforms" />
      </div>
    </div>
    <div class="home__artworks">
      <div class="home__artwork" v-for="(artwork, key) in artworks" :key="`artwork-${key}`">
        <div
          class="home__artwork-image"
          :style="{
            backgroundImage: `url(${artwork.search_image})`,
          }"
          :alt="artwork.title"
        ></div>
        <div
          class="home__artwork-container"
          @click="$router.push({ path: `/artworks/${artwork.slug}` })"
        >
          <p class="home__artwork-text text-sub-title">Click to View</p>
          <div class="home__artwork-text-mobile">
            <h3 class="home__artwork-title text-title">{{ artwork.title }}</h3>
            <div class="home__artwork-sub-title">
              <p class="home__artwork-sub-title--text">
                {{ artwork.artist }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InfiniteLoading class="infinite-loading" @infinite="loadMore" />
    <div class="home__footer" v-if="hasMorePages">
      <BaseButton class="button--white" text="LOAD MORE ARTWORKS" @click="loadMore" />
    </div>
  </section>
</template>

<script>
import home_page_image from "@/assets/images/homepage_background.jpg";

import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconScroll from "@/components/icons/IconScroll.vue";
import SharePlatforms from "@/components/SharePlatforms.vue";
import InfiniteLoading from "vue-infinite-loading";
import Copyright from "@/components/Copyright.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Loader,
    BaseButton,
    IconScroll,
    SharePlatforms,
    InfiniteLoading,
    Copyright,
  },
  data() {
    return {
      loading: false,
      busy: false,
      page: 1,
      home_page_image,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Home",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content:
            "A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Home browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Home browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Home browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content:
            "A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content:
            "A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    this.loading = true;
    await this.apiGetFeaturedExhibition();
    await this.apiGetArtworks({
      page: this.page,
      exhibition_id: this.featuredExhibition.id ? this.featuredExhibition.id : false,
    });
    this.loading = false;
  },
  computed: {
    ...mapGetters("publicapi/home", {
      artworks: "getArtworks",
      hasMorePages: "getHasMorePages",
      featuredExhibition: "getFeaturedExhibition",
    }),
  },
  methods: {
    ...mapActions("publicapi/home", ["apiGetArtworks", "apiGetFeaturedExhibition"]),
    async loadMore($state) {
      this.busy = true;
      if (this.hasMorePages) {
        this.page += 1;
        await this.apiGetArtworks({ page: this.page });
        $state?.loaded();
        this.busy = false;
      } else {
        $state?.complete();
      }
    },
    scrollToElement() {
      const el = this.$refs.works;

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-width: 100vw;
  min-height: 100vh;
  background-color: $lightBlack;
  &__header {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: $lightBlack;
    &-image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      object-fit: cover;
      @media screen and (max-width: $xxs) {
        width: auto;
        max-width: none;
      }
    }
    &-container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      //padding: 23rem 10rem 0 31.5rem;
      padding: 12rem 0;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      //background-color: rgba($color: $black, $alpha: 0.45);
      @media only screen and (max-width: $sm) {
        padding: 30px 0;
        justify-content: flex-end;
      }
      &-content {
        width: 70%;
        padding: 0 30px;
        margin: 0 auto;
        @media only screen and (max-width: $md) {
          width: 100%;
          padding: 0 12rem 0 5rem;
        }
        @media only screen and (max-width: $sm) {
          padding: 0 30px;
        }
        @media screen and (max-width: $xxxs) {
          padding: 0 20px;
        }
      }
    }
    &-title {
      font-size: 5.2rem;
      margin-bottom: 30px;
      max-width: 68rem;
      font-weight: 300;
      b {
        font-weight: 500;
      }
      @media only screen and (max-width: $md) {
        max-width: 75%;
        font-size: 35px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: $sm) {
        max-width: 400px;
        font-size: 35px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      @media screen and (max-width: $xxs) {
        max-width: 100%;
      }
      &--small {
        font-weight: 300;
      }
    }
    &-sub-title {
      margin-bottom: 5px;
      font-size: 1.8rem;
      line-height: 1.5;
      text-transform: uppercase;
      @media only screen and (max-width: $sm) {
        max-width: 400px;
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }
    &-description {
      //font-size: 3rem;
      //line-height: 1.3;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 4rem;
      // @media only screen and (max-width: $xl) {
      //   font-size: 20px;
      // }
      // @media only screen and (max-width: $md) {
      //   font-size: 18px;
      // }
      @media only screen and (max-width: $sm) {
        margin-bottom: 20px;
        //font-size: 16px;
      }
    }
    &-buttons {
      margin-top: 2rem;
      display: flex;
      @media only screen and (max-width: $sm) {
        flex-direction: column;
        max-width: 400px;
        gap: 20px 0;
        margin-bottom: 40px;
      }
    }
    &-button {
      margin-right: 15px;
      @media only screen and (max-width: $sm) {
        margin-right: 0;
        width: 100%;
      }
    }
    &-copyright {
      position: absolute;
      bottom: 13.5rem;
      left: 10.5rem;
      &::v-deep .copyright,
      &.copyright {
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
      }
      @media only screen and (max-width: $md) {
        left: 5rem;
      }
      @media only screen and (max-width: $sm) {
        left: 0;
        bottom: 0;
        transform: translateY(-50%);
      }
    }
  }
  &__icon-scroll {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    @media only screen and (max-width: $md) {
      bottom: -5rem;
    }
    @media screen and (max-width: $xxs) {
      bottom: -7rem;
    }
  }
  &__artworks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    margin-top: -2rem;
    @media screen and (max-width: $sm) {
      margin-top: 0;
    }
    @media screen and (max-width: $xxs) {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 7px;
    }
  }
  &__artwork {
    position: relative;
    height: 33.33vw;
    &:nth-of-type(3n + 1) {
      margin-top: -8rem;
    }
    /*&:nth-of-type(3n + 2) {
      margin-top: -20px;
    }*/
    &:nth-of-type(3n) {
      margin-top: -16rem;
    }
    &:hover {
      .home__artwork-container {
        opacity: 1;
        background-color: rgba($color: $blue, $alpha: 0.83);
        @media screen and (max-width: $xxs) {
          background-color: transparent;
        }
      }
    }
    @media screen and (max-width: $sm) {
      &:nth-of-type(n) {
        margin-top: 0;
      }
    }
    @media screen and (max-width: $xxs) {
      height: 100vw;
    }
    &-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    &-container {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px 10px 3rem 3rem;
      background-color: rgba($color: $blue, $alpha: 0.83);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: all 0.3s ease;
      opacity: 0;
      @media screen and (max-width: $xxs) {
        opacity: 1;
        background: none;
      }
      @media only screen and (max-width: $md) {
        padding: 10px;
      }
      @media screen and (max-width: $xxs) {
        padding: 14px 20px;
      }
    }
    &-text-mobile {
      @media screen and (max-width: $xxs) {
        max-width: 280px;
        border: 0.4px solid $white;
        border-radius: 5px;
        padding: 8px 10px;
        background-color: rgba($color: $black, $alpha: 0.45);
        backdrop-filter: blur(2.7px);
      }
    }
    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      line-height: 1.67;
      letter-spacing: -1px;
      @media only screen and (max-width: $md) {
        font-size: 18px;
      }
      @media only screen and (max-width: $sm) {
        font-size: 15px;
      }
      @media screen and (max-width: $xxs) {
        opacity: 0;
      }
    }
    &-title {
      font-size: 4rem;
      line-height: 1.25;
      letter-spacing: -1.33px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      text-overflow: clip;
      overflow: hidden;
      @media only screen and (max-width: $xl) {
        font-size: 32px;
      }
      @media only screen and (max-width: $md) {
        font-size: 25px;
      }
      @media only screen and (max-width: $sm) {
        font-size: 20px;
      }
      @media screen and (max-width: $xxs) {
        font-size: 18px;
      }
    }
    // &-price {
    //   font-size: 3.125rem;
    //   line-height: 1.55;
    //   margin-bottom: 8px;
    //   @media only screen and (max-width: $xl) {
    //     font-size: 20px;
    //   }
    // }
    &-sub-title {
      display: flex;
      justify-content: space-between;
      &--text {
        font-size: 2.35rem;
        line-height: 1.3;
        letter-spacing: -1px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        text-overflow: clip;
        overflow: hidden;
        @media only screen and (max-width: $xl) {
          font-size: 20px;
        }
        @media only screen and (max-width: $md) {
          font-size: 18px;
        }
        @media only screen and (max-width: $sm) {
          font-size: 16px;
        }
        @media screen and (max-width: $xxs) {
          font-size: 14px;
        }
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.6rem 25px 24rem 25px;
    @media screen and (max-width: $xs) {
      padding: 7.6rem 25px 11rem 25px;
    }
  }
  &__share-platforms {
    position: absolute;
    right: 10rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    &--mobile {
      display: none;
    }
    @media screen and (max-width: $md) {
      right: 5.5rem;
    }
    @media screen and (max-width: $sm) {
      display: none;
      right: 3.5rem;
      &--mobile {
        display: flex;
        position: relative;
        right: 0;
        top: 0;
        transform: none;
        &.share-platforms {
          flex-direction: row;
          max-height: 50px;
          width: 100%;
          max-width: 50px;
        }
        &.share-platforms--visible {
          max-width: 340px;
          @media screen and (max-width: $xxs) {
            max-width: 295px;
          }
          @media screen and (max-width: $xxxxxs) {
            max-width: 285px;
          }
        }
      }
    }
  }
  .mobile-copyright {
    @media screen and (max-width: $sm) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>

